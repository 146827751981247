<script setup>
import Swal from "sweetalert2";
import ErrorComp from "@/components/errormessage.vue";
import getCookie from "@/scripts/utils/getCookie.js";
import loadercomp from "@/components/loadercomp";
import { onMounted, ref } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import router from "@/router";
import { useStore } from "vuex";
const password = ref(null);
const password2 = ref(null);
const activation = ref(null);
const checked = ref(null);
const promoCode = ref(null);
const route = useRoute();
const store = useStore();

onMounted(() => {
    if (route.query.c) {
        activation.value = route.query.c;
    }
});
const handleResend = () => {
    store.commit("toggleLoader");
    window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.VUE_APP_reCAPTCHA_SITE_KEY, { action: "resend_activation" }).then(async (rcpt) => {
            const response = await fetch(`/api/activate/resend/${route.query.a}`, {
                method: "POST",
                body: JSON.stringify({
                    recaptcha_token: rcpt,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
            });
            const data = await response.json();
            store.commit("toggleLoader");
            if (data.ok) {
                Swal.fire({
                    icon: "success",
                    titleText: "Resent Activation Code",
                    confirmButtonColor: "#417dd8",
                    text: `

                     `,
                    confirmButtonText: "Activate account",
                });
            } else {
                store.state.errMsg = "An error occurred sending your activation code";
            }
        });
    });
};
const handleActivate = () => {
    promoCode.value === true ? promoCode.value.toUpperCase : null;
    if (password.value != password2.value) {
        store.state.errMsg = "Passwords do not match";
    } else if (password.value.length < 8) {
        store.state.errMsg = "Your password must be at least 8 characters";
    } else {
        store.state.loader = true;
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.VUE_APP_reCAPTCHA_SITE_KEY, { action: "activate" }).then(async (recaptchaToken) => {
                const response = await fetch(`/api/activate/${route.query.a}`, {
                    method: "POST",
                    body: JSON.stringify({
                        recaptcha_token: recaptchaToken,
                        activation_code: activation.value,
                        password: password.value,
                        terms: checked.value,
                        promo_code: promoCode.value,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCookie("csrftoken"),
                    },
                });
                const data = await response.json();
                store.state.loader = false;
                if (data.ok) {
                    if (typeof window.dataLayer == "object") {
                        window.dataLayer.push({ event: "signup-complete" });
                    }
                    Swal.fire({
                        icon: "success",
                        titleText: "SignUp Successful",
                        text: `
                    
                    `,
                        confirmButtonColor: "#417dd8",
                        confirmButtonText: "Continue to Dashboard",
                    });
                    store.state.authenticated = true;
                    router.push({ name: "overview" });
                } else {
                    store.state.authenticated = false;
                    switch (data.error) {
                        case "bad-activation-code":
                            store.state.errMsg = "Invalid activation code. Double check your e-mail and try again.";
                            break;
                        case "bad-promo":
                            store.state.errMsg = "The promo code you entered is not available. Double check and try again.";
                            break;
                        default:
                            store.state.errMsg = "An unknown error has occurred. Please try again.";
                            break;
                    }
                }
            });
        });
    }
};
</script>

<template>
    <div>
        <div class="buidl_wrap flex flex_col">
            <loadercomp v-if="$store.state.loader" />
            <h2 class="ta_center">SIGN UP 2/2</h2>
            <p>Check your email for your activation code and enter it below</p>
            <form action="" class="rivet_signup auth_form flex flex_col" @submit.prevent="handleActivate">
                <ErrorComp :errMsg="$store.state.errMsg" />

                <div class="input_data">
                    <div class="activation_code_input">
                        <input v-model="activation" autocomplete="cc-number" required type="text" placeholder="Activation code" />
                    </div>
                    <div class="select_password">
                        <input
                            v-model="password"
                            required
                            autocomplete="new-password"
                            type="password"
                            placeholder="Choose a password (atleast 8 characters)"
                        />
                    </div>
                    <div class="confirm_password">
                        <input v-model="password2" required autocomplete="new-password" type="password" placeholder="Confirm password" />
                    </div>
                    <div class="promo_code">
                        <input v-model="promoCode" type="text" placeholder="Promo code (if available)" />
                    </div>
                    <div class="t_c">
                        <input v-model="checked" required type="checkbox" />
                        &nbsp;
                        <span
                            >I agree to Rivet's
                            <router-link to="/">Terms of use</router-link>
                        </span>
                    </div>
                    <div @click.prevent="handleResend" class="resend_mail btn_web3_from_prompt">
                        <button class="flex flex_r"><span>Resend Email</span>&nbsp;&nbsp; <i class="ri-mail-send-line"></i></button>
                    </div>
                </div>
                <div class="btn btn_color ta_center">
                    <button>Submit</button>
                </div>
            </form>
            <div class="haveAccount ta_center">
                <p>
                    Already have an account?
                    <router-link class="routing" :to="{ name: 'login' }">Login</router-link>
                </p>
            </div>
        </div>
        <div class="background_items">
            <div class="img1 img">
                <img src="https://res.cloudinary.com/ferventdev/image/upload/v1655148340/rivet.cloud/eth_3d_ctfa27.png" alt="" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/style/auth/signup.scss";
</style>
